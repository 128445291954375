import { FieldTimeOutlined } from '@ant-design/icons'
import { Form, Input } from 'antd'
import { t } from 'i18next'
import { useMemo } from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import { IconTextContainer } from '../../../components'
import { NO_DATA_PLACEHOLDER, dateFormat } from '../../../constants/constants'
import { useLiteratureReviews } from '../../../hooks'
import { RootState } from '../../../store'

const { TextArea } = Input

const LiteratureReviewDetailsOverviewInfoPanel = () => {
  const { selectedLiteratureReview } = useLiteratureReviews({
    preventFetch: true,
  })

  const literatureReviewOwner = useMemo(
    () => selectedLiteratureReview?.createdBy?.displayName,
    [selectedLiteratureReview]
  )
  const literatureReviewLastModifiedDate = useMemo(
    () => selectedLiteratureReview?.lastModifiedDate,
    [selectedLiteratureReview]
  )

  const { currentUser } = useSelector((state: RootState) => state.user)

  const isCurrentUserOwner = useMemo(
    () => currentUser?.id === selectedLiteratureReview?.createdBy?.id,
    [selectedLiteratureReview, currentUser]
  )
  return (
    <div>
      <Form.Item
        label={t('Title')}
        required
        name="title"
        rules={[
          { required: true, message: t('errorMessage.isRequired') || '' },
        ]}
      >
        <Input readOnly={!isCurrentUserOwner} />
      </Form.Item>
      <Form.Item label={t('Description')} name="description">
        <TextArea rows={4} readOnly={!isCurrentUserOwner} style={{}} />
      </Form.Item>
      <Form.Item label={t('Owner')}>
        {literatureReviewOwner || NO_DATA_PLACEHOLDER}
      </Form.Item>
      <Form.Item label={t('Last update')}>
        <IconTextContainer>
          <FieldTimeOutlined />

          {literatureReviewLastModifiedDate ? (
            <Moment
              date={literatureReviewLastModifiedDate}
              format={dateFormat.PRIMARY}
            />
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </IconTextContainer>
      </Form.Item>
      <Form.Item label={t('Type')}>
        {(selectedLiteratureReview?.reviewType &&
          t(
            `literatureReviewDetailsPage.searchReviewTypes.${selectedLiteratureReview?.reviewType}`
          )) ||
          NO_DATA_PLACEHOLDER}
      </Form.Item>
    </div>
  )
}

export default LiteratureReviewDetailsOverviewInfoPanel
