import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import moment from 'moment'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import {
  ConfirmDeleteModal,
  DeleteIconButton,
  ListPageHeader,
  ListPageLayout,
  PageContentDropdown,
  SectionLoadingState,
  TableBoldColumnContainer,
} from '../../components'
import {
  NO_DATA_PLACEHOLDER,
  dateFormat,
  searchReviewTypes,
} from '../../constants/constants'
import { useLiteratureReviews } from '../../hooks'
import notificationService from '../../services/notificationService'
import { RootState } from '../../store'
import { LiteratureReview } from '../../store/reducers/literatureReviewReducer'
import {
  literatureReviewPanelTypes,
  literatureReviewTabs,
} from '../LiteratureReviewDetailsPage/LiteratureReviewDetailsPage'
import { routePaths } from '../RootPage'

interface ExtendedLiteratureReview extends LiteratureReview {
  key: string
}

const LiteratureReviewListPage = () => {
  const navigate = useNavigate()
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const { currentUser } = useSelector((state: RootState) => state.user)

  const {
    literatureReviewList,
    loading,
    exportLiteratureReviewListCsv,
    deleteLiteratureReview,
  } = useLiteratureReviews({
    preventFetch: false,
  })

  const formattedData: ExtendedLiteratureReview[] =
    useMemo(
      () =>
        literatureReviewList?.map((literatureReview: LiteratureReview) => ({
          ...literatureReview,
          key: literatureReview.id,
        })),
      [literatureReviewList]
    ) || []

  const handleClick = (id: string) => {
    navigate(
      `${routePaths.SEARCH_REVIEW_DETAILS}/${id}/${literatureReviewPanelTypes.OVERVIEW}/${literatureReviewTabs.INFO}`
    )
  }

  const handleLiteratureReviewListPageExport = () => {
    exportLiteratureReviewListCsv()
  }

  const handleConfirmDelete = () => {
    if (deleteId) {
      setDeleteLoading(true)
      deleteLiteratureReview(deleteId || '', navigate)
        .then((response: LiteratureReview) => {
          navigate(routePaths.SEARCH_REVIEW_LIST)
          setDeleteLoading(false)
          notificationService.notificationSuccess(
            t('literatureReviewListPage.deleteSuccessAlertMessage')
          )
        })
        .finally(() => {
          setDeleteLoading(false)
        })
    }
    setConfirmDeleteModalOpen(false)
    setDeleteId(undefined)
  }

  const handleDeleteClick = useCallback((event: MouseEvent, id: string) => {
    event.stopPropagation()
    setDeleteId(id)
    setConfirmDeleteModalOpen(true)
  }, [])

  const columns: ColumnsType<LiteratureReview> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      width: '40%',
      sorter: (a: LiteratureReview, b: LiteratureReview) =>
        a.title && b.title ? a.title.localeCompare(b.title) : -1,
      render: (text) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t('Type'),
      dataIndex: 'reviewType',
      key: 'reviewType',
      width: '10%',
      render: (reviewType) => (
        <>
          {reviewType === searchReviewTypes.HISTORICAL_NC_REVIEW
            ? t('literatureReviewListPage.historicalReview')
            : t('literatureReviewListPage.literatureReview') ||
              NO_DATA_PLACEHOLDER}
        </>
      ),
    },
    {
      title: t('Owner'),
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '20%',
      sorter: (a: LiteratureReview, b: LiteratureReview) =>
        a.createdBy?.displayName && b.createdBy?.displayName
          ? a.createdBy.displayName.localeCompare(b.createdBy.displayName)
          : -1,
      render: (createdBy) => (
        <>{createdBy.displayName || NO_DATA_PLACEHOLDER}</>
      ),
    },
    {
      title: t('Last Update'),
      dataIndex: 'lastModifiedDate',
      key: 'lastModifiedDate',
      width: '20%',
      sorter: (a: LiteratureReview, b: LiteratureReview) =>
        a.lastModifiedDate && b.lastModifiedDate
          ? moment(a.lastModifiedDate).valueOf() -
            moment(b.lastModifiedDate).valueOf()
          : -1,
      render: (lastModifiedDate) => (
        <>
          {lastModifiedDate ? (
            <Moment local format={dateFormat.PRIMARY}>
              {lastModifiedDate}
            </Moment>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      render: (text, { id, createdBy }) => (
        <>
          {(currentUser?.role?.permissions.some((permission) =>
            permission.includes('admin')
          ) ||
            currentUser?.id === createdBy?.id) && (
            <DeleteIconButton
              tooltipTitle={t(
                'literatureReviewListPage.deleteLiteratureReviewTooltip'
              )}
              onClick={(e: MouseEvent) => handleDeleteClick(e, id)}
            />
          )}
        </>
      ),
    },
  ]

  return loading ? (
    <SectionLoadingState />
  ) : (
    <>
      <ListPageLayout>
        <ListPageHeader
          title={t('literatureReviewListPage.title', {
            count: literatureReviewList?.length,
          })}
          actions={
            <PageContentDropdown
              onExport={handleLiteratureReviewListPageExport}
              disabled={formattedData.length === 0}
            />
          }
        />
        <Table
          rowClassName="page-list-table-row clickable"
          columns={columns}
          dataSource={formattedData}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                handleClick(record.id)
              }, // click row
            }
          }}
          scroll={{ y: 'calc(100vh - 155px)' }}
        />
      </ListPageLayout>
      <ConfirmDeleteModal
        open={confirmDeleteModalOpen}
        onConfirm={handleConfirmDelete}
        onCancel={() => setConfirmDeleteModalOpen(false)}
        loading={deleteLoading}
      >
        {t('literatureReviewDetailsPage.confirmDeleteModalContent')}
      </ConfirmDeleteModal>
    </>
  )
}

export default LiteratureReviewListPage
