import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import useWorkspaceDetails from '../../../../hooks/useWorkspaceDetails'
import { routePaths } from '../../../RootPage'
import SmartFunctionHeader from '../../components/SmartFunctionHeader'

const DelSmartFunctionDetailsPageHeader = () => {
  const { workspaceId } = useParams()

  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const backButtonUrl = useMemo(
    () =>
      `${routePaths.WORKSPACE_DETAILS}/${selectedWorkspace?.id}/overview/smartFunction`,
    [selectedWorkspace?.id]
  )

  return (
    <SmartFunctionHeader
      backButtonUrl={backButtonUrl}
      totalFound={0}
      hideTotalAndSearchLine={true}
    ></SmartFunctionHeader>
  )
}

export default DelSmartFunctionDetailsPageHeader
