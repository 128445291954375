import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ReadOutlined,
} from '@ant-design/icons'
import { Button, FormInstance } from 'antd'
import { t } from 'i18next'
import { memo, useMemo } from 'react'
import styled from 'styled-components'
import {
  SearchReviewSavingType,
  searchReviewSavingTypes,
} from '../../../../../constants/constants'

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 8px;
`

interface CreateLiteratureReviewModalFooterProps {
  onCancel: () => void
  onNext?: () => void
  nextDisabled?: boolean
  onPrevious?: () => void
  onSubmit?: () => void
  form?: FormInstance
}

const CreateLiteratureReviewModalFooter = memo(
  ({
    onCancel,
    onNext,
    nextDisabled = false,
    onPrevious,
    onSubmit,
    form,
  }: CreateLiteratureReviewModalFooterProps) => {
    const savingType = useMemo(() => {
      if (!form) {
        return searchReviewSavingTypes.NEW
      }

      return (
        searchReviewSavingTypes[
          form.getFieldValue('savingType') as SearchReviewSavingType
        ] || searchReviewSavingTypes.NEW
      )
    }, [form])

    return (
      <Footer>
        <Button onClick={onCancel} type="text">
          {t('Cancel')}
        </Button>
        {onPrevious && (
          <Button
            type="default"
            icon={<ArrowLeftOutlined />}
            onClick={onPrevious}
          >
            {t('Previous')}
          </Button>
        )}
        {onNext && (
          <Button
            icon={<ArrowRightOutlined />}
            disabled={nextDisabled}
            type="primary"
            onClick={onNext}
          >
            {t('Next')}
          </Button>
        )}
        {onSubmit && (
          <Button icon={<ReadOutlined />} type="primary" onClick={onSubmit}>
            {savingType === searchReviewSavingTypes.NEW
              ? t('createLiteratureReviewModal.submitButtonNew')
              : t('createLiteratureReviewModal.submitButtonExisting')}
          </Button>
        )}
      </Footer>
    )
  }
)

export default CreateLiteratureReviewModalFooter
