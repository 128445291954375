import { Form, FormInstance, Input, InputRef } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo, useRef } from 'react'
import { SelectLiteratureReview } from '../../../../../components'
import {
  SearchReviewSavingType,
  searchReviewSavingTypes,
  SearchReviewType,
  searchReviewTypes,
} from '../../../../../constants/constants'
import { LiteratureReview } from '../../../../../store/reducers/literatureReviewReducer'

interface NewTabProps {
  inputContent: string
  setInputContent: (content: string) => void
}

const NewTab = ({ inputContent, setInputContent }: NewTabProps) => {
  const inputRef = useRef<InputRef>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <Form.Item
      label={t(
        'createLiteratureReviewModal.steps.definitionStep.newReviewLabel'
      )}
      required
      rules={[{ required: true, message: t('errorMessage.isRequired') || '' }]}
      extra={t(
        'createLiteratureReviewModal.steps.definitionStep.newReviewExtra'
      )}
      name="title"
    >
      <Input
        placeholder={
          t(
            'createLiteratureReviewModal.steps.definitionStep.newReviewLabelPlaceholder'
          ) || ''
        }
        ref={inputRef}
        value={inputContent}
        onChange={(event) => setInputContent(event.target.value)}
      />
    </Form.Item>
  )
}

interface ExistingTabProps {
  setLiteratureReview: (literatureReview: LiteratureReview | undefined) => void
  form: FormInstance
}

const ExistingTab = ({ setLiteratureReview, form }: ExistingTabProps) => {
  const onLiteratureReviewSelected = (
    LiteratureReview: LiteratureReview | undefined
  ) => {
    if (!LiteratureReview) {
      return
    }
    form.setFieldValue('title', LiteratureReview?.title)
    setLiteratureReview(LiteratureReview)
  }

  const selectedReviewType = useMemo(() => {
    return (
      (form.getFieldValue('reviewType') as SearchReviewType) ||
      searchReviewTypes.LITERATURE_REVIEW
    )
  }, [form])

  return (
    <Form layout="vertical">
      <Form.Item
        label={t(
          'createLiteratureReviewModal.steps.definitionStep.existingReviewLabel'
        )}
        extra={t(
          'createLiteratureReviewModal.steps.definitionStep.existingReviewExtra'
        )}
      >
        <SelectLiteratureReview
          onChange={onLiteratureReviewSelected}
          selectedType={selectedReviewType}
        />
      </Form.Item>
    </Form>
  )
}

interface CreateLiteratureReviewDefinitionStepProps {
  form: FormInstance
  handleLiteratureReview: (
    literatureReview: LiteratureReview | undefined
  ) => void
  setReviewTitle: (title: string) => void
  reviewTitle: string
}

const CreateLiteratureReviewDefinitionStep = ({
  form,
  handleLiteratureReview,
  setReviewTitle,
  reviewTitle,
}: CreateLiteratureReviewDefinitionStepProps) => {
  const savingType = useMemo(() => {
    if (!form) {
      return searchReviewSavingTypes.NEW
    }

    return (
      searchReviewSavingTypes[
        form.getFieldValue('savingType') as SearchReviewSavingType
      ] || searchReviewSavingTypes.NEW
    )
  }, [form])

  const tabs = {
    NEW: <NewTab inputContent={reviewTitle} setInputContent={setReviewTitle} />,
    EXISTING: (
      <ExistingTab setLiteratureReview={handleLiteratureReview} form={form} />
    ),
  }

  return <>{tabs[savingType]}</>
}

export default CreateLiteratureReviewDefinitionStep
