import { Divider, FormInstance } from 'antd'
import { t } from 'i18next'
import { useMemo } from 'react'
import styled from 'styled-components'
import { FilterBadge, InfoSection } from '../../../../../components'
import {
  searchReviewSavingTypes,
  searchReviewTypes,
} from '../../../../../constants/constants'
import { DataSource } from '../../../../../store/reducers/dataSourceReducer'
import ModalFormReviewSection from '../../../../DataStoreListPage/modals/component/ModalFormReviewSection'

const BadgesContainer = styled.div`
  display: flex;
  gap: 8px;
`

export interface CreateLiteratureReviewFinalStepProps {
  form: FormInstance
  query: string
  source?: DataSource
  period?: string
  total?: number
}

const CreateLiteratureReviewFinalStep = ({
  form,
  query,
  source,
  period,
  total,
}: CreateLiteratureReviewFinalStepProps) => {
  const reviewType = useMemo(
    () => form.getFieldValue('reviewType') || '',
    [form]
  )

  const savingType = useMemo(
    () => form.getFieldValue('savingType') || '',
    [form]
  )

  const title = useMemo(() => form.getFieldValue('title') || '', [form])

  return (
    <>
      {reviewType && (
        <ModalFormReviewSection
          label={t(
            'createLiteratureReviewModal.steps.reviewStep.searchReviewTypeTitle'
          )}
          value={
            reviewType === searchReviewTypes.HISTORICAL_NC_REVIEW
              ? t(
                  'createLiteratureReviewModal.steps.typeStep.historicalReviewType'
                )
              : t(
                  'createLiteratureReviewModal.steps.typeStep.literatureReviewType'
                )
          }
        />
      )}
      {savingType && (
        <ModalFormReviewSection
          label={t(
            'createLiteratureReviewModal.steps.reviewStep.searchReviewNatureTitle'
          )}
          value={
            savingType === searchReviewSavingTypes.EXISTING
              ? t(
                  'createLiteratureReviewModal.steps.setupStep.existingReviewLabel'
                )
              : t('createLiteratureReviewModal.steps.setupStep.newReviewLabel')
          }
        />
      )}
      {title && (
        <ModalFormReviewSection
          label={t(
            'createLiteratureReviewModal.steps.reviewStep.searchReviewTitle'
          )}
          value={title}
        />
      )}
      <Divider />
      <InfoSection
        label={t(
          'createLiteratureReviewModal.steps.definitionStep.inlcusionTitle'
        )}
      >
        <div>{query}</div>
        <BadgesContainer>
          {source?.title && (
            <FilterBadge color="default">
              {source.typeCode}: {source?.title}
            </FilterBadge>
          )}
          {period && <FilterBadge color="success">{period}</FilterBadge>}
        </BadgesContainer>
        <div>
          {t('createLiteratureReviewModal.steps.definitionStep.totalResult', {
            count: total || 0,
          })}
        </div>
      </InfoSection>
    </>
  )
}

export default CreateLiteratureReviewFinalStep
