import { FileTextOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  NO_DATA_PLACEHOLDER,
  workspaceTypes,
} from '../../../../constants/constants'
import { useAlgorithmRunDetails, useWorkspaceDetails } from '../../../../hooks'
import {
  AlgorithmRunConfigValues,
  QualityReviewConfigValues,
  QualityReviewRunResult,
} from '../../../../store/reducers/workspaceReducer'
import { routePaths } from '../../../RootPage'
import SmartFunctionHeader from '../../components/SmartFunctionHeader'
import { QualityReviewFilterType } from '../QualityReviewFunctionDetailsPage'
import SelectQualityReviewDeviationFilterChoice from './SelectQualityReviewDeviationFilterChoice'

interface QualityReviewSmartFunctionDetailsPageHeaderProps {
  handleFilterChange: (value: QualityReviewFilterType | undefined) => void
  setSearch: (search: string) => void
  search: string
}

const QualityReviewSmartFunctionDetailsPageHeader = ({
  handleFilterChange,
  setSearch,
  search,
}: QualityReviewSmartFunctionDetailsPageHeaderProps) => {
  const { workspaceId, algorithmRunId } = useParams()

  const { selectedAlgorithmRun } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
  })
  const smartFunctionResult = useMemo(
    () => selectedAlgorithmRun?.runResult as QualityReviewRunResult,
    [selectedAlgorithmRun?.runResult]
  )

  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const searchedItems = useMemo(() => {
    if (!smartFunctionResult) return []

    const filesnames = Object.keys(smartFunctionResult)

    return filesnames.filter((item) => (search ? item?.includes(search) : true))
  }, [search, smartFunctionResult])

  const backButtonUrl = useMemo(
    () =>
      `${routePaths.WORKSPACE_DETAILS}/${selectedWorkspace?.id}/overview/smartFunction`,
    [selectedWorkspace?.id]
  )

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value)
    },
    [setSearch]
  )

  // Type guard function to check if config is of type QualityReviewConfigValues
  const isQualityReviewConfig = (
    config?: AlgorithmRunConfigValues
  ): config is QualityReviewConfigValues => {
    if (!config) return false
    return 'documentTemplateName' in config
  }

  const afterDataSource = selectedWorkspace?.typeCode ===
    workspaceTypes.OFR_QR && (
    <Tag
      icon={<FileTextOutlined />}
      color="default"
      style={{
        marginLeft: '8px',
      }}
    >
      {isQualityReviewConfig(selectedAlgorithmRun?.config)
        ? selectedAlgorithmRun.config.documentTemplateName ||
          NO_DATA_PLACEHOLDER
        : NO_DATA_PLACEHOLDER}
    </Tag>
  )

  return (
    <SmartFunctionHeader
      backButtonUrl={backButtonUrl}
      totalFound={searchedItems.length || 0}
      onSearchAction={handleSearch}
      onSearchChange={(event) => handleSearch(event.target.value)}
      afterDataSource={afterDataSource}
    >
      <SelectQualityReviewDeviationFilterChoice onChange={handleFilterChange} />
    </SmartFunctionHeader>
  )
}

export default QualityReviewSmartFunctionDetailsPageHeader
