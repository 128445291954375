import {
  ArrowLeftOutlined,
  ReloadOutlined,
  CheckOutlined,
} from '@ant-design/icons'
import { Button, Form } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'
import OTPInput from 'react-otp-input'
import styled from 'styled-components'
import { Spacer } from '../../../components'
import { authService } from '../../../services'
import notificationService from '../../../services/notificationService'
import { ForgotPasswordStepsFooter, FormLayout } from '../ForgotPasswordPage'
import { AxiosError, isAxiosError } from 'axios'
import { httpErrorStatus } from '../../../constants/constants'

const MainLabelContainer = styled.div`
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 12px;
`

const StyledInput = styled.input`
  min-width: 40px;
  min-height: 40px;
  padding: 0px 12px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;

  &:hover {
    border-color: #9261e0;
  }
  &:focus {
    border-color: #9261e0;
    outline: 0;
  }
`

interface FormValues {
  pin: string
}

interface ForgotPasswordStep2Props {
  onNext: (pin: string) => void
  onCancel: () => void
  onResend: (token: string) => void
  email: string
  inviteToken: string
  onCodeError: () => void
}

const ForgotPasswordStep2Code = ({
  onNext,
  onCancel,
  email,
  onResend,
  inviteToken,
  onCodeError,
}: ForgotPasswordStep2Props) => {
  const [loading, setLoading] = useState(false)

  const handleResendPassword = async () => {
    setLoading(true)
    await authService.forgotPasswordInvite(email).then(
      (response: any) => {
        notificationService.notificationSuccess(
          t('forgotPasswordPage.step2Code.successResendPin')
        )
        setLoading(false)
        onResend(response.inviteToken)
      },
      (error) => {
        console.error('Login error', error)
        setLoading(false)
        onCodeError()
      }
    )
  }

  const handleSubmit = async (values: FormValues) => {
    setLoading(true)
    await authService.forgotPasswordValidatePin(inviteToken, values.pin).then(
      (response: any) => {
        setLoading(false)
        onNext(values.pin)
      },
      (error: AxiosError | Error) => {
        console.error('Login error', error)
        setLoading(false)
        if (
          isAxiosError(error) &&
          error.response?.status === httpErrorStatus.ERROR_403_FORBIDDEN
        ) {
          onCodeError()
        }
      }
    )
  }

  return (
    <Form onFinish={handleSubmit} layout="vertical">
      <FormLayout>
        <div>
          <MainLabelContainer>
            {t('forgotPasswordPage.step2Code.introduction')}
          </MainLabelContainer>
          <Form.Item
            label={t('forgotPasswordPage.step2Code.codeLabel')}
            name="pin"
            extra={t('forgotPasswordPage.step2Code.helper')}
          >
            <OTPInput
              onChange={() => {}}
              numInputs={6}
              containerStyle={{ display: 'flex', gap: 8 }}
              renderInput={(props) => (
                <StyledInput {...props} disabled={loading} />
              )}
            />
          </Form.Item>
        </div>

        <ForgotPasswordStepsFooter>
          <Spacer />
          <Button
            onClick={onCancel}
            icon={<ArrowLeftOutlined />}
            type="default"
          >
            {t('forgotPasswordPage.stepsCancelButton')}
          </Button>
          <Button
            onClick={handleResendPassword}
            icon={<ReloadOutlined />}
            type="text"
          >
            {t('forgotPasswordPage.step2Code.resendPinButton')}
          </Button>
          <Button type="primary" htmlType="submit" icon={<CheckOutlined />}>
            {t('forgotPasswordPage.step2Code.okButton')}
          </Button>
        </ForgotPasswordStepsFooter>
      </FormLayout>
    </Form>
  )
}

export default ForgotPasswordStep2Code
