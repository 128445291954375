import { Form, FormInstance, Radio, Space } from 'antd'
import { t } from 'i18next'
import { searchReviewTypes } from '../../../../../constants/constants'

export interface CreateLiteratureReviewTypeStepProps {
  form: FormInstance
}

const CreateLiteratureReviewTypeStep = ({
  form,
}: CreateLiteratureReviewTypeStepProps) => {
  return (
    <Form.Item
      label={t('createLiteratureReviewModal.steps.typeStep.subHeader')}
      required
      rules={[{ required: true, message: t('errorMessage.isRequired') || '' }]}
      name="reviewType"
    >
      <Radio.Group>
        <Space direction="vertical">
          <Radio value={searchReviewTypes.HISTORICAL_NC_REVIEW}>
            {t(
              'createLiteratureReviewModal.steps.typeStep.historicalReviewType'
            )}
          </Radio>
          <Radio value={searchReviewTypes.LITERATURE_REVIEW}>
            {t(
              'createLiteratureReviewModal.steps.typeStep.literatureReviewType'
            )}
          </Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  )
}

export default CreateLiteratureReviewTypeStep
