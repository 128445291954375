import { urlList } from '../constants/urlConstant'
import {
  dataSourceMock,
  literatureReviewMock,
  ofrDocumentTemplateListMock,
  userMock,
  workspaceMock,
  workspaceDetailsMock,
  workspaceAlgorithmRunBiomarkMock,
  workspaceFoldersMock,
  ofrDocumentTemplateDetailsMock,
} from '../mocks/dev'

// To use mock turn this constant to true
export const useMock = false

interface MockUrlInfo {
  url: string
  pathElements?: number // number of element in the url separated with a /
  secondaryPathElement?: string
  mock: Object
  force: boolean
}

const mockTable: MockUrlInfo[] = [
  {
    url: urlList.WORKSPACES,
    pathElements: 3,
    mock: workspaceMock,
    force: false,
  },
  {
    url: urlList.WORKSPACES,
    pathElements: 4,
    mock: workspaceDetailsMock,
    force: false,
  },
  {
    url: urlList.WORKSPACES,
    pathElements: 6,
    mock: workspaceAlgorithmRunBiomarkMock,
    force: false,
  },
  {
    url: urlList.WORKSPACES,
    pathElements: 5,
    secondaryPathElement: 'folders',
    mock: workspaceFoldersMock,
    force: false,
  },
  {
    url: urlList.SEARCH_REVIEWS,
    pathElements: 3,
    mock: literatureReviewMock,
    force: false,
  },
  {
    url: urlList.USERS,
    pathElements: 3,
    mock: userMock,
    force: false,
  },
  {
    url: urlList.DATA_SOURCES,
    pathElements: 3,
    mock: dataSourceMock,
    force: false,
  },
  {
    url: urlList.OFR_DOCUMENT_TEMPLATES,
    pathElements: 3,
    mock: ofrDocumentTemplateListMock,
    force: false,
  },
  {
    url: urlList.OFR_DOCUMENT_TEMPLATES,
    pathElements: 4,
    mock: ofrDocumentTemplateDetailsMock,
    force: false,
  },
]

const secondaryElementCheck = (rawApi: string, mockedUrl: MockUrlInfo) =>
  mockedUrl.secondaryPathElement
    ? rawApi.includes(mockedUrl.secondaryPathElement)
    : true

const isApiMocked = (api: string) => {
  const rawApi = api.split('?')[0]
  const cleanApi = rawApi.split('/').slice(0, 3).join('/')
  const pathElement = rawApi.split('/').length

  const isMockFound = mockTable.find((mockedUrl) => {
    const secondaryElementCheckPassed = secondaryElementCheck(rawApi, mockedUrl)
    return (
      mockedUrl.url === cleanApi &&
      mockedUrl.pathElements === pathElement &&
      secondaryElementCheckPassed &&
      (mockedUrl.force || useMock)
    )
  })

  return !!isMockFound
}

const getMock = async (api: string) => {
  const rawApi = api.split('?')[0]
  const cleanApi = rawApi.split('/').slice(0, 3).join('/')
  const pathElement = rawApi.split('/').length

  const mockInfo = mockTable.find((mockedUrl) => {
    const secondaryElementCheckPassed = secondaryElementCheck(rawApi, mockedUrl)
    return (
      mockedUrl.url === cleanApi &&
      mockedUrl.pathElements === pathElement &&
      secondaryElementCheckPassed
    )
  })
  return new Promise(async (resolve, reject) => {
    if (mockInfo?.mock) {
      setTimeout(() => {
        resolve(mockInfo?.mock)
      }, 100)
    } else {
      reject(Error('Mock not found'))
    }
  })
}

const mockService = {
  getMock,
  isApiMocked,
}

export default mockService
