import { Form, FormInstance, Radio, Space } from 'antd'
import { t } from 'i18next'
import { searchReviewSavingTypes } from '../../../../../constants/constants'

export interface CreateLiteratureReviewSetupStepProps {
  form: FormInstance
}

const CreateLiteratureReviewSetupStep = ({
  form,
}: CreateLiteratureReviewSetupStepProps) => {
  return (
    <Form.Item
      label={t('createLiteratureReviewModal.steps.setupStep.subHeader')}
      required
      rules={[{ required: true, message: t('errorMessage.isRequired') || '' }]}
      name="savingType"
    >
      <Radio.Group>
        <Space direction="vertical">
          <Radio value={searchReviewSavingTypes.EXISTING}>
            {t(
              'createLiteratureReviewModal.steps.setupStep.existingReviewLabel'
            )}
          </Radio>
          <Radio value={searchReviewSavingTypes.NEW}>
            {t('createLiteratureReviewModal.steps.setupStep.newReviewLabel')}
          </Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  )
}

export default CreateLiteratureReviewSetupStep
