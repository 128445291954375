import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  BackButton,
  ListPageLayout,
  PageContentDropdown,
  SearchTable,
  Spacer,
  SubHeader,
} from '../../components'
import { SearchTableRow } from '../../components/SearchTable'
import FilterBadge from '../../components/display/FilterBadge'
import { dateFormat } from '../../constants/constants'
import useLiteratureReviews from '../../hooks/useLiteratureReviews'
import i18n from '../../i18n/i18n'
import { RootState } from '../../store'
import { DataSource } from '../../store/reducers/dataSourceReducer'
import {
  literatureReviewPanelTypes,
  literatureReviewTabs,
} from '../LiteratureReviewDetailsPage/LiteratureReviewDetailsPage'
import { routePaths } from '../RootPage'

const FloatLeftContainer = styled.div`
  display: flex;
  margin-left: auto;
  order: 2;
  gap: 8px;
  align-items: center;
  margin-left: auto;
  white-space: nowrap;
`

const BadgesContainer = styled.div`
  display: flex;
  gap: 4px;
`

const LiteratureReviewSearchDetailsPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [loadingIds, setLoadingIds] = useState<string[]>([])
  const {
    selectedSearchQuery,
    selectedLiteratureReview,
    exportLiteratureReviewSearchQueryCsv,
    summarizeLiteratureReviewSearchQueryResult,
    refreshLiteratureReviewList,
    saveSearchQueryInclusion,
    getSearchQuerySourceIdForLiteratureReview,
  } = useLiteratureReviews(params)

  // refresh every 30 seconds for new updates on the search results summary status
  useEffect(() => {
    const interval = setInterval(() => {
      // we only want to refresh the list if there are no loadingIds
      if (loadingIds.length === 0) refreshLiteratureReviewList()
    }, 30000)
    return () => clearInterval(interval)
  }, [loadingIds, refreshLiteratureReviewList])

  const formattedData: SearchTableRow[] = useMemo(
    () =>
      selectedSearchQuery?.searchResults
        ? selectedSearchQuery?.searchResults.map((document, index) => {
            return {
              ...document,
              key: index.toString(),
              id: document?.id || '',
              title: document?.title,
              publicationName: document?.publicationName || '',
              authors: document?.authors,
              publicationDate: document?.publicationDate || '',
              included:
                selectedLiteratureReview?.includedSearchResults?.includes(
                  document?.id || ''
                ),
              documentLink: document?.documentLink,
              language: document.language || '',
              summaryStatus: document?.summaryStatus || '',
            }
          })
        : [],
    [selectedSearchQuery, selectedLiteratureReview]
  )
  const dataSources = useSelector(
    (state: RootState) => state.dataSource.dataSourceList
  )
  const source: DataSource | undefined = useMemo(
    () =>
      dataSources.find(
        (source) =>
          source.id ===
          getSearchQuerySourceIdForLiteratureReview(
            selectedSearchQuery?.searchSource
          )
      ),
    [
      dataSources,
      getSearchQuerySourceIdForLiteratureReview,
      selectedSearchQuery,
    ]
  )
  const sourceText = useMemo(
    () =>
      typeof source?.title === 'string'
        ? source?.title
        : source?.title[i18n.language],
    [source]
  )

  const handleSummarize = (resultId: string) => {
    if (!selectedLiteratureReview && !resultId) return
    summarizeLiteratureReviewSearchQueryResult({
      literatureReviewId: selectedLiteratureReview?.id || '',
      resultId,
    })
  }

  const appendLoadingId = (recordId: string) => {
    const allLoadingIds = [...loadingIds, recordId]
    setLoadingIds(allLoadingIds)
  }

  const removeLoadingId = (recordId: string) => {
    const allLoadingIds = loadingIds.filter((id) => id !== recordId)
    setLoadingIds(allLoadingIds)
  }
  const handleOnSwitchClick = async (recordId: string) => {
    // append to loadingIds
    appendLoadingId(recordId)

    const handleResponse = () => {
      removeLoadingId(recordId)
    }

    try {
      await saveSearchQueryInclusion({
        recordId,
        navigate,
      })
      // remove from loadingIds
      handleResponse()
    } catch (error) {
      console.error('axios fetch error', error)
      // remove from loadingIds
      handleResponse()
    }
  }

  const handleExport = () => {
    exportLiteratureReviewSearchQueryCsv(
      selectedLiteratureReview?.id || '',
      selectedSearchQuery?.searchEvent || ''
    )
  }

  const handleOnRowClick = (recordId: string) => {
    navigate(
      `${routePaths.SEARCH_REVIEW_DOCUMENT}/${selectedLiteratureReview?.id}/${params.searchId}/${recordId}`
    )
  }

  return (
    <ListPageLayout>
      <SubHeader>
        <BackButton
          tooltipMessage={
            t('literatureReviewSearchDetailsPage.backButtonTooltipMessage') ||
            ''
          }
          url={`${routePaths.SEARCH_REVIEW_DETAILS}/${selectedLiteratureReview?.id}/${literatureReviewPanelTypes.OVERVIEW}/${literatureReviewTabs.QUERIES}`}
        />
        <div>
          {t('Query')}: {selectedSearchQuery?.searchText}
        </div>
        <Spacer />
        <FloatLeftContainer>
          <div>
            <Moment
              date={selectedSearchQuery?.searchDate}
              format={dateFormat.PRIMARY}
            />
          </div>
          <BadgesContainer>
            {source && <FilterBadge color="default">{sourceText}</FilterBadge>}
            {selectedSearchQuery?.searchParameters.date && (
              <FilterBadge color="success">
                {selectedSearchQuery?.searchParameters.date}
              </FilterBadge>
            )}
          </BadgesContainer>
          <div>
            {t('Result total')}: {selectedSearchQuery?.searchResults.length}
          </div>
          <PageContentDropdown onExport={handleExport} />
        </FloatLeftContainer>
      </SubHeader>
      <SearchTable
        formattedData={formattedData}
        useInclusionToggle
        onSwitchClick={(recordId) => handleOnSwitchClick(recordId)}
        loadingIds={loadingIds}
        onClick={(recordId) => handleOnRowClick(recordId)}
        maxHeight="calc(100vh - 180px)"
        onSummarize={handleSummarize}
      />
    </ListPageLayout>
  )
}

export default LiteratureReviewSearchDetailsPage
