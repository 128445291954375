import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Radio } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IconTextContainer } from '../../components'
import { routePaths } from '../RootPage'
import {
  LiteratureReviewTab,
  literatureReviewPanelTypes,
  literatureReviewTabs,
} from './LiteratureReviewDetailsPage'

const LiteratureReviewTabs = () => {
  const { panelType, tab, literatureReviewId } = useParams()
  const navigate = useNavigate()
  const [selectedValue, setSelectedValue] = useState<LiteratureReviewTab>(
    (tab || literatureReviewTabs.INFO) as LiteratureReviewTab
  )

  useEffect(() => {
    setSelectedValue((tab || literatureReviewTabs.INFO) as LiteratureReviewTab)
  }, [tab])

  const handleChange = (value: LiteratureReviewTab) => {
    setSelectedValue(value)
    navigate(
      `${routePaths.SEARCH_REVIEW_DETAILS}/${literatureReviewId}/${
        panelType || literatureReviewPanelTypes.OVERVIEW
      }/${value || literatureReviewTabs.INFO}`
    )
  }

  return (
    <Radio.Group
      value={selectedValue}
      defaultValue={literatureReviewTabs.INFO}
      buttonStyle="solid"
    >
      <Radio.Button
        value={literatureReviewTabs.INFO}
        onClick={() =>
          handleChange(literatureReviewTabs.INFO as LiteratureReviewTab)
        }
      >
        <IconTextContainer>
          <InfoCircleOutlined />
          {t('literatureReviewDetailsPage.literatureReviewTabs.tabInfo')}
        </IconTextContainer>
      </Radio.Button>
      <Radio.Button
        value={literatureReviewTabs.QUERIES}
        onClick={() =>
          handleChange(literatureReviewTabs.QUERIES as LiteratureReviewTab)
        }
      >
        <IconTextContainer>
          <SearchOutlined />
          {t('literatureReviewDetailsPage.literatureReviewTabs.tabQueries')}
        </IconTextContainer>
      </Radio.Button>
    </Radio.Group>
  )
}

export default LiteratureReviewTabs
