import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { SourceType } from './dataSourceReducer'
import { User } from './userReducer'

export interface Document {
  id: string
  oid: string | null
  urn: string
  authors: string[]
  dataSource?: string
  csv: string
  documentLink: string
  language: string | null
  publicationDate: string | null
  publicationName: string | null
  title: string
  summary?: string
  tags?: string
  summaryStatus?: string
  description?: string
  literatureReview: string
  searchEvent: string
}

export interface SearchParameter {
  dataType: string[]
  date: string
  language: string
  page: number
  pageSize: number
  searchMethod: string
  searchQuery: string
  source: number
}

export interface SearchSourceConfig {
  authorsKey: string
  languageKey?: string
  databaseName: string
  authorNameKey: string
  documentIdKey?: string
  documentLinkBase: string
  documentTitleKey: string
  publicationDateKey: string
  publicationNameKey: string
}

export interface SearchSource {
  id: string
  clusterId: string | null
  externalId: string | null
  oid: string
  sourceType: SourceType
  title: string
  description: string | null
  visibility: string
  organizationName: string
  contactName: string | null
  contactEmail: string | null
  config: SearchSourceConfig
  defaultSource: boolean
  participants: User[]
  createdBy: string
  lastModifiedBy: string
  createdDate: string
  lastModifiedDate: string
  typeName: string
  typeCode: string
  isInternal: boolean
}

export interface SearchQuery {
  id: string
  searchActionBy: User
  searchDate: string
  searchDescription: string
  searchEvent: string
  searchParameters: SearchParameter
  searchResults: Document[]
  searchSource: SearchSource
  searchText: string
  uiFlags: number
  literatureReview: string
}

export interface LiteratureReview {
  id: string
  oid: string | null
  contributers?: string[]
  createdBy?: User
  createdDate?: string
  description?: string | null
  includedSearchResults?: string[]
  lastModifiedBy?: string
  lastModifiedDate?: string
  searchQueries?: SearchQuery[]
  title?: string
  uiFlags?: number
  reviewType: string
}

export interface LiteratureReviewState {
  literatureReviewList?: LiteratureReview[]
  selected?: LiteratureReview
  selectedSearchQuery?: SearchQuery
  selectedSearchResultItem?: Document
  detailsCsv?: string
  listCsv?: string
  searchQueryCsv?: string
  loading?: boolean
}

const initialState: LiteratureReviewState = {
  literatureReviewList: undefined,
  selected: undefined,
  selectedSearchQuery: undefined,
  selectedSearchResultItem: undefined,
  detailsCsv: undefined,
  listCsv: undefined,
  searchQueryCsv: undefined,
  loading: false,
}

const literatureReviewSlice = createSlice({
  name: 'literatureReviews',
  initialState,
  reducers: {
    setLiteratureReviewList: (
      state,
      action: PayloadAction<LiteratureReview[]>
    ) => {
      state.literatureReviewList = action.payload
    },
    setSelectedLiteratureReview: (
      state,
      action: PayloadAction<LiteratureReview>
    ) => {
      state.selected = action.payload
    },
    setSelectedSearchQuery: (state, action: PayloadAction<SearchQuery>) => {
      state.selectedSearchQuery = action.payload
    },
    setSelectedSearchResultItem: (state, action: PayloadAction<Document>) => {
      state.selectedSearchResultItem = action.payload
    },
    setLiteratureReviewLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setListCsv: (state, action: PayloadAction<string>) => {
      state.listCsv = action.payload
    },
    setSearchQueryCsv: (state, action: PayloadAction<string>) => {
      state.searchQueryCsv = action.payload
    },
    setDetailsCsv: (state, action: PayloadAction<string>) => {
      state.detailsCsv = action.payload
    },
    clearLiteratureReviewState: () => initialState,
  },
})

export const {
  setLiteratureReviewList,
  setSelectedLiteratureReview,
  setSelectedSearchQuery,
  setSelectedSearchResultItem,
  setLiteratureReviewLoading,
  clearLiteratureReviewState,
  setListCsv,
  setSearchQueryCsv,
  setDetailsCsv,
} = literatureReviewSlice.actions

export const getLiteratureReview = (state: RootState) => state.literatureReview

const literatureReviewReducer = literatureReviewSlice.reducer
export default literatureReviewReducer
